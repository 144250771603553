section.snackbar {
    position: fixed;
    left: 10%;
    right: 10%;
    bottom: 20px;
    background: $main-text-color;
    border-radius: 5px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.21);
    animation: showIn 1s cubic-bezier(.21,.76,.81,1.46);
    p {
        font-size: 16px;
        color: white; }
    &.none {
        display: none; }
    &.hidden {
        bottom: 0;
        transform: translateY(100%);
        opacity: 0;
        transition: bottom 1s cubic-bezier(.76,-0.48,.83,.67) , transform 1s cubic-bezier(.76,-0.48,.83,.67) , opacity 1s ease-in-out .4s; }
    @include phone {
        left: 3%;
        right: 3%; }
    .content {
        padding: 20px 50px; }
    button.hide {
        position: absolute;
        right: 20px;
        top: 20px;
        width: 20px;
        height: 20px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        @include phone {
            right: 10px;
            top: 10px; }
        svg {
            width: 20px;
            height: 20px; } }
    a {
        color: $main-color;
        font-size: 20px;
        padding: 10px 0px;
        display: block;
        font-family: $font-text;
        text-decoration: none;
        transition: color .5s ease-in-out;
        &:hover {
            color: lighten($main-color, 15%); } }
    @keyframes showIn {
        from {
            opacity: 0;
            bottom: 0;
            transform: translateY(100%); }
        to {
            opacity: 1;
            bottom: 20px;
            transform: translateY(0%); } } }
