.nav-group {
    transition: background-color .5s ease-in-out, box-shadow .5s ease-in-out;
    width: 100%;
    height: 70px;
    background-color: transparent;
    position: absolute;
    box-shadow: none;
    z-index: 9998;
    .mobile-click {
        display: none;
        button {
            display: none;
            @include tablet {
                display: block;
                outline: none;
                height: 40px;
                width: 40px;
                border: none;
                background-color: transparent; } }
        @include tablet {
            display: block;
            width: 40px;
            height: 40px;
            position: absolute;
            right: 5%;
            z-index: 10000;
            top: 16px;
            svg {
                fill: white;
                transition: fill .5s ease-in-out;
                .top, .bottom {
                    transform-origin: center;
                    transition: transform .4s ease-in-out; }
                .middle {
                    transition: opacity .4s ease-in-out; } } }

        &.expand {
            //position: fixed
            svg {
                //position: absolute
                .top {
                    transform: rotate(45deg) translate(0, 24%); }
                .bottom {
                    transform: rotate(-45deg) translate(0, -24%); }
                .middle {
                    opacity: 0; } } } }
    &.sticky {
        position: fixed;
        top: 0;
        background-color: white;
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
        transition: background-color .5s ease-in-out .5s, box-shadow .5s ease-in-out .5s;
        .mobile-click {
            svg {
                @include tablet {
                    fill: black; } } }
        nav.menu {
            top: 20px;
            ul {
                li {
                    @include phone {
                        line-height: 2em; }
                    a {
                        font-size: 18px;
                        color: $main-text-color;
                        text-shadow: none;
                        transition: text-shadow .5s ease-in-out .5s, color .5s ease-in-out .5s, border-bottom-color .5s ease-in-out; } }
                &.access-nav {
                    position: absolute;
                    right: 0;
                    top: 0;
                    @include phone {
                        position: static; }

                    li {
                        a {
                            padding: 10px 15px;
                            background-color: $main-color;
                            border: 2px solid $main-color;
                            color: white;
                            @include phone {
                                background-color: $sec-color;
                                border-color: $sec-color; } } } } } }
        .nav-bar {
            .logo {
                //width: 50px
                top: 10px;
                transition: width .5s ease-in-out .2s, top .5s ease-in-out;
                width: 140px;
                img.bl {
                    opacity: 1; }
                img.wh {
                    opacity: 0; } } } }

    nav.menu {
        position: absolute;
        z-index: 10;
        width: 100%;
        top: 30px;
        text-align: right;
        transition: top .5s linear, right .5s linear;
        text-align: center;
        @include tablet {
            display: none; }
        ul {
            li {
                @include phone {
                    line-height: 2em;
                    color: $main-text-color; }
                a {
                    @include phone {
                        color: $main-text-color; } } }

            padding: 0;
            &.main-nav {
                padding: 0 170px;
                @include phone {
                    padding: 0 10px; } }
            &.access-nav {
                position: absolute;
                right: 0;
                top: 0;
                @include phone {
                    position: static; }
                li {
                    a {
                        padding: 15px 25px;
                        background-color: rgba(0, 0, 0, 0.3);
                        border: 2px solid $main-color;
                        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
                        transition: padding .5s ease-in-out, background-color .5s ease-in-out; } } }
            li {
                display: inline-block;
                padding: 0 20px;
                a {
                    transition: text-shadow .5s ease-in-out, color .5s ease-in-out;
                    text-decoration: none;
                    @include tablet {
                        text-shadow: none; } }
                &.selected {
                    a {} } } }
        &.expand {
            @include tablet {
                display: block;
                position: fixed;
                background-color: $main-color;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0; }
            ul {
                @include tablet {
                    display: block;
                    padding-top: 400px; }
                @include phone {
                    padding-top: 160px; }

                li {
                    @include tablet {
                        text-align: center;
                        display: block;
                        padding: 0;
                        font-size: 24px; }
                    @include phone {
                        font-size: 18px; }
                    a {
                        @include tablet {
                            font-size: 24px; }
                        @include phone {
                            font-size: 18px; } } } } } }
    .nav-bar {
        position: relative;
        .logo {
            position: absolute;
            left: 0;
            top: 0;
            width: 140px;
            z-index: 999;
            left: 50px;
            top: 20px;
            transition: width .5s ease-in-out .5s, top .5s ease-in-out;
            @include tablet {
                width: 240px;
                left: 50%;
                top: 100px;
                transform: translateX(-50%); }
            @include phone {
                width: 120px;
                top: 30px; }
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                transition: opacity .5s ease-in-out .5s;
                &.wh {
                    opacity: 1; }
                &.bl {
                    opacity: 0; } } }
        &.expand {
            .logo {
                z-index: 999;
                position: fixed; } } }
    .d-link {
        cursor: pointer;
        transition: border-bottom-color 2.5s ease-in-out;
        border-bottom: 1px solid transparent;
        &.selected {
            border-bottom-color: $main-color;
            @include tablet {
                border-bottom: 0; } }
        @include tablet {
            display: none; } }
    .sub-links {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        max-height: 0px;
        opacity: 1;
        z-index: -1;
        transition: transform .5s ease-in-out, opacity .5s ease-in-out, max-height .5s ease-in-out;
        background-color: $main-text-color;
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
        transform: translateY(50px);
        @include tablet {
            max-height: initial;
            position: static;
            z-index: 0;
            height: auto;
            box-shadow: none;
            opacity: 1;
            transform: translateY(0);
            background-color: transparent;
            padding-top: 0 !important;
            padding-bottom: 0!important; }
        li {
            padding-top: 12px !important;
            padding-bottom: 12px !important;
            pointer-events: none;
            @include tablet {
                pointer-events: auto;
                padding-top: 0 !important;
                padding-bottom: 0!important; }
            a.i-link {
                color: white !important;
                opacity: 0;
                font-size: 18px !important;
                transition: opacity .5s ease-in-out;
                pointer-events: none;
                @include tablet {
                    color: $main-text-color !important;
                    pointer-events: auto;
                    opacity: 1; } } }
        &.show {
            max-height: 50px;
            pointer-events: auto;
            li {
                pointer-events: auto;
                opacity: 1;
                transition: opacity .5s ease-in-out .3s;
                a.i-link {
                    pointer-events: auto;
                    opacity: 1;
                    transition: opacity .5s ease-in-out .3s; } }
            @include tablet {
                transform: translateY(0); } } } }
