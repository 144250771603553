section.widget.showcase {
    background-color: $sec-color;
    .content {
        padding-left: 10%;
        padding-right: 10%;
        @include phone {
            padding-left: 5%;
            padding-right: 5%; }
        .slides {
            overflow: hidden;
            white-space: nowrap; }
        ul.switch-list {
            display: block;
            text-align: center;
            li {
                display: inline-block;
                width: 33%;
                text-align: center;
                a {
                    padding-bottom: 20px;
                    font-size: 36px;
                    color: $light-text-color;
                    cursor: pointer;
                    position: review;
                    transition: color .4s ease-in-out;
                    @include tablet {
                        font-size: 18px; }
                    &.selected {
                        color: $main-color;
                        border-bottom: 1px solid $main-color; }
                    &:hover {
                        color: $main-color; } } } }
        .slide {
            white-space: normal;
            padding-top: 60px;
            position: relative;
            display: inline-block;
            width: 100%;
            padding: 60px 5% 0 60px;
            @include tablet {
                vertical-align: top;
                padding: 10px 5% 0 10px; }
            .content-blocks {
                vertical-align: top;
                display: inline-block;
                width: 30%;
                min-width: 460px;
                padding-right: 40px;
                @include tablet {
                    width: 100%;
                    padding: 30px 0;
                    min-width: auto; }
                .content-block {
                    &:first-child {
                        margin-bottom: 60px; }
                    h4 {
                        margin-bottom: 10px;
                        color: #ffffff; }
                    p {} } }
            .slider {
                vertical-align: top;
                display: inline-block;
                width: 70%;
                max-width: calc(100% - 460px);
                position: relative;
                padding-bottom: 46.5%;
                overflow: hidden;

                @include tablet {
                    width: 100%;
                    max-width: unset !important;
                    padding-bottom: 60%; }
                .container {
                    height: 100%;
                    position: absolute;
                    overflow: hidden;
                    white-space: nowrap;
                    img {
                        vertical-align: top;
                        object-fit: cover;
                        width: 100%;
                        display: inline-block; }
                    .slider-content {
                        transition: transform 1s linear;
                        transform: translate(0px);
                        position: relative; } }
                .selector {
                    position: absolute;
                    left: 50%;
                    bottom: 30px;
                    transform: translateX(-50%);
                    @include tablet {
                        width: 100%;
                        text-align: center;
                        margin: auto;
                        bottom: 12px; }
                    > * {
                        cursor: pointer;
                        display: inline-block;
                        margin: 6px 6px;
                        background-color: white;
                        width: 20px;
                        height: 20px;
                        box-shadow: 0 3px 6px black;
                        &.selected {
                            background-color: $main-color; } } } } } } }
