section.widget.contact {
    .content {
        @include tablet {
            width: 90%;
            padding: 0;
            margin: auto; }
        .half {
            width: 50%;
            display: inline-block;
            vertical-align: top;
            padding-left: 20px;
            @include tablet {
                padding-top: 40px;
                width: 100%;
                padding-left: 0; }
            &:first-child {
                text-align: right;
                padding-right: 20px;
                padding-left: 0;
                @include tablet {
                    padding-right: 0; } }
            h4 {
                margin-top: 24px;
                font-size: 28px; }
            p {
                color: $main-text-color;
                font-size: 16px;
                padding: 12px 0;
                &.bold {
                    padding-bottom: 4px;
                    font-weight: 600; } }
            span.info {
                font-family: $font-text;
                color: $sec-text-color;
                font-style: italic; }
            h2.bordered {
                position: relative;
                padding-bottom: 20px;
                &::after {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    content: ' ';
                    height: 1px;
                    width: 86px;
                    background-color: $main-color; } }
            a {
                display: block;
                font-family: $font-text;
                text-decoration: none;
                color: $main-text-color; }

            .card {
                width: 600px;
                max-width: 100%;
                background-color: white;
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
                img {
                    width: 100%; }
                .card-content {
                    text-align: left;
                    padding: 30px; }
                h3.card-title {
                    text-transform: uppercase;
                    font-size: 28px;
                    padding-bottom: 16px;
                    margin-bottom: 16px;
                    position: relative;
                    &::after {
                        position: absolute;
                        content: ' ';
                        height: 1px;
                        width: 120px;
                        background-color: $main-color;
                        bottom: 0;
                        left: 0; } }
                .address {
                    color: $sec-text-color;
                    font-family: $font-text;
                    padding-bottom: 30px; }
                .contact-links {
                    span.info {
                        font-family: $font-text;
                        color: $sec-text-color; }
                    a {
                        padding: 4px 0;
                        i {
                            color: $main-color;
                            padding-right: 8px; } } }
                .emergency {
                    span {
                        color: $sec-text-color; }
                    a {
                        display: inline; } } } } } }
