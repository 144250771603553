section.widget.form {
    padding: 100px 0;
    form {
        background-color: white;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
        padding: 40px;
        width: 550px;
        max-width: 90%;
        margin: auto;
        .success {
            display: none; }
        &.success {
            .success {
                display: block;
                padding: 16px 0;
                font-size: 20px; }
            button {
                display: none; } }
        h4 {
            text-align: center;
            padding-top: 20px; }
        .input-group {
            position: relative;
            padding-top: 25px;
            padding-bottom: 2px;
            margin-top: 30px;
            &.hp {
                opacity: 0;
                width: 0;
                height: 0;
                margin: 0;
                padding: 0;
                * {
                    opacity: 0;
                    width: 0;
                    height: 0;
                    margin: 0;
                    padding: 0; } }
            &.no-border {
                border-bottom: none; }
            &.multiple {
                padding-top: 0; }
            .error-message {
                display: block;
                color: red;
                font-family: $font-text; }
            input, textarea {
                border: 1px solid lightgray;
                font-family: $font-text;
                font-size: 20px;
                width: 100%;
                padding: 8px;
                &.has-error {
                    border-color: red; } }
            textarea {
                resize: none;
                height: 80px;
                font-size: 16px; }
            label {
                position: absolute;
                color: $sec-text-color;
                top: 0;
                left: 0; } }
        button {
            display: block;
            cursor: pointer;
            font-family: $font-text;
            margin-top: 16px;
            border: none;
            background-color: $main-color;
            color: white;
            padding: 10px 30px;
            font-size: 18px;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }
        .g-recaptcha {
            display: inline-block;
            margin-top: 38px;
            @include phone {
                transform: scale(0.77);
                -webkit-transform: scale(0.77);
                transform-origin: 0 0;
                -webkit-transform-origin: 0 0; }
            &.error {
                border: 1px solid red; } } } }
