section.widget.map {
    padding-top: 100px;
    width: 80%;
    margin: auto;
    @include tablet {
        width: 100%;
        margin-top: 40px; }
    #google-map, #google-map-2 {
        width: 100%;
        height: 440px; }
    iframe {
        height: 440px;
        width: 100%; } }
