footer {
    width: 100%;
    padding: 70px 15%;
    background-color: $third-color;
    @include tablet {
        padding: 50px 5%; }
    @include print {
        background-color: white; }
    .list {
        a.address {
            color: white;
            font-family: $font-text;
            display: block;
            text-decoration: none;
            padding-bottom: 10px;
            transition: color .5s ease-in-out;
            &:hover {
                color: $main-color; } }
        display: inline-block;
        vertical-align: top;
        width: 40%;
        padding-right: 10px;
        &:first-child {
            width: 20%;
            @include phone {
                width: 100%;
                padding-bottom: 10px; } }
        @include phone {
            width: 100%; }
        h5 {
            font-size: 24px;
            color: white;
            @include phone {
                padding-top: 30px;
                font-size: 20px; } }
        p {
            color: white;
            font-size: 20px;
            padding-bottom: 16px;
            @include phone {
                font-size: 16px; } }
        ul {
            padding-bottom: 16px;
            list-style-type: none;
            @include phone {
                padding-top: 10px; }
            li, li a {
                font-family: $font-text;
                font-size: 18px;
                color: white;
                font-weight: 300;
                line-height: 28px;
                text-decoration: none;
                transition: color .3s ease-in-out;
                @include phone {
                    font-size: 14px; }
                span {
                    padding-left: 10px; }
                a {
                    cursor: pointer;
                    &:hover {
                        color: $light-text-color; } }
                &.bold {
                    font-weight: 600; } } }
        img {
            width: 80%; } } }
