section.widget.prevoyance {
    .content-holder {
        display: inline-block;
        width: 30%;
        height: 100vh;
        vertical-align: top;
        position: relative;
        background-color: $third-color;

        @include tablet {
            width: 100%;
            height: auto; }
        .content-block {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 20%;
            right: 20%;
            @include tablet {
                position: static;
                transform: translateY(0);
                padding: 70px 10% 20px 10%; } }
        h4 {
            color: white;
            padding-bottom: 30px;
            margin-bottom: 30px;
            position: relative;
            &::after {
                content: ' ';
                position: absolute;
                bottom: 0;
                width: 64px;
                height: 3px;
                border-radius: 1.5px;
                left: 0;
                background-color: $main-color; } }
        p {
            color: $light-text-color;
            margin-bottom: 50px;
            line-height: 30px; }
        a.call-to-action {} }
    .slider {
        vertical-align: top;
        display: inline-block;
        width: 70%;
        height: 100vh;
        position: relative;
        padding-bottom: 46.5%;
        overflow: hidden;
        @include tablet {
            width: 100%;
            height: 60vh; }
        .container {
            height: 100%;
            position: absolute;
            overflow: hidden;
            white-space: nowrap;
            img {
                vertical-align: top;
                object-fit: cover;
                width: 100%;
                height: 100%;
                display: inline-block; }
            .slider-content {
                transition: transform 1s linear;
                transform: translate(0px);
                position: relative; } }
        .selector {
            position: absolute;
            left: 50%;
            bottom: 12px;
            transform: translateX(-50%);
            .select {
                cursor: pointer;
                display: inline-block;
                margin: 0 6px;
                background-color: white;
                width: 20px;
                height: 20px;
                box-shadow: 0 3px 6px black;
                &.selected {
                    background-color: $main-color; } } } } }
