section.widget.condoleances {
    background-color: $main-text-color;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10000;
    transform: translateY(100%);
    transition: transform 1s ease-in-out, opacity 1s ease-in-out .2s;
    opacity: 0;
    @include tablet {
        overflow-y: scroll;
        height: 100vh;
        -webkit-overflow-scrolling: touch; }
    &.show {
        opacity: 1;
        transform: translateY(0%); }

    button.close {
        z-index: 100;
        width: 40px;
        height: 40px;
        background-color: transparent;
        border: none;
        position: absolute;
        right: 40px;
        top: 40px;
        i {
            color: $main-color;
            font-size: 40px; } }

    img.background {
        opacity: .3;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        @include tablet {
            height: 270%; } }
    .content {
        @include tablet {
            width: 90%;
            padding: 50px 0;
            margin: auto; }
        h3, h4 {
            text-align: center;
            color: white; }
        .third {
            width: 33%;
            display: inline-block;
            vertical-align: top;
            padding: 20px 30px;
            @include tablet {
                width: 100%; }
            .condoleances-examples {
                label {
                    top: 14px; }
                position: relative;
                ul {
                    height: 400px;
                    overflow: scroll;
                    padding-top: 40px;
                    li {
                        font-family: $font-text;
                        list-style-type: none;
                        line-height: 1.8em;
                        position: relative;
                        margin-bottom: 12px;
                        cursor: pointer;
                        padding: 4px;
                        transition: background-color .5s ease-in-out;
                        &:hover {
                            background-color: rgba($main-color, 0.9);
                            i {
                                color: white; } }
                        i {
                            position: absolute;
                            left: 4px;
                            top: 10px;
                            color: $main-color;
                            transition: color .5s ease-in-out; }
                        span {
                            display: inline-block;
                            padding-left: 20px;
                            color: white; } } } }
            .input-group {
                width: 100%;
                position: relative;
                padding-top: 20px;
                padding-bottom: 2px;
                margin-top: 10px; }
            .error-message {
                color: #e74c3c;
                font-family: $font-text;
                font-size: 14px;
                opacity: 0;
                transition: opacity .3s ease-in-out; }
            input,textarea {
                border: 1px solid $sec-text-color;
                width: 100%;
                font-size: 20px;
                font-family: $font-text;
                padding: 8px 5px;
                background-color: rgba(51, 51, 51 , 0.6);
                color: white;
                outline: none;
                transition: border-color .3s ease-in-out;
                @include tablet {
                    font-size: 16px; }
                &.has-error {
                    border-color: #e74c3c;
                    ~ .error-message {
                        opacity: 1; } }
                &:focus {
                    border-color: $main-color;
                    + label {
                        color: $main-color; } } }

            label {
                font-family: $font-text;
                position: absolute;
                color: $light-text-color;
                top: 0;
                left: 0;
                transition: color .3s ease-in-out; }
            textarea {
                height: 340px;
                resize: none; }

            .input {
                padding-top: 20px;
                position: relative;
                display: inline-block;
                vertical-align: top;
                width: 30%;
                text-align: right;
                label {
                    right: 0;
                    left: auto; } }
            button.send-condo {
                margin-top: 10px;
                font-family: $font-text;
                background-color: $main-color;
                color: white;
                border: none;
                outline: none;
                font-size: 20px;
                padding: 10px 15px;
                cursor: pointer;
                @include tablet {
                    position: absolute;
                    bottom: 0; }
                i {
                    display: none; }
                &:disabled {
                    background-color: $light-text-color;
                    &.waiting {
                        span {
                            display: none; }
                        i {
                            animation: rotate_spinner 2s linear infinite;
                            display: block; }
                        @keyframes rotate_spinner {
                            from {
                                transform: rotate(0deg); }
                            to {
                                transform: rotate(360deg); } } } } } } }

    .message {
        width: 80%;
        color: white;
        font-family: $font-text;
        font-size: 20px;
        margin: auto;
        margin-bottom: 40px; }
    .disclaimer {
        width: 80%;
        margin: auto;
        margin-bottom: 40px;
        p {
            text-align: justify;
            color: $light-text-color;
            font-size: 14px; } } }
