section.intro {
    padding: 0;
    .content {
        position: relative;
        padding: 80px 15%;
        text-align: center;
        @include phone {
            padding: 10% 5%; }
        h2 {
            margin-bottom: 20px; }
        p {
            font-size: 20px;
            line-height: 32px;
            padding-top: 16px;
            @include phone {
                font-size: 16px;
                line-height: 24px; }
            &:first-of-type {
                padding-top: 0; } }
        &::after {
            content: ' ';
            position: absolute;
            bottom: 0;
            height: 1px;
            background-color: $sec-text-color;
            left: 50%;
            transform: translateX(-50%);
            width: 50%; } } }
