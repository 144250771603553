section.widget.necro {
    .content {
        text-align: center;
        padding: 80px 10%;
        a.call-to-action {
            margin-top: 20px; }
        h2 {
            padding: 40px 0; }
        .necrologies {
            text-align: center;
            margin: 40px 0;
            .necrologie {
                padding: 40px 40px 20px 40px;
                display: inline-block;
                vertical-align: top;
                width: 400px;
                margin: 30px;
                border: 1px solid $light-text-color;
                @include phone {
                    width: 100%;
                    margin: 30px 0;
                    padding: 20px 10px 20px 10px; }
                img.avatar {
                    width: 200px;
                    height: 200px;
                    border-radius: 50%;
                    margin-bottom: 15px;
                    object-fit: cover;
                    @include phone {
                        width: 120px;
                        height: 120px;
                        margin-bottom: 15px; } }
                h4.name {
                    padding: 15px 0; }
                p {
                    line-height: 30px;
                    font-family: $font-headers; }

                .dates {
                    padding: 15px 0;
                    p.date-naissance {}
                    p.date-deces {} }
                .funerailles {
                    padding: 15px 0;
                    p.label {}
                    p.date-funerailles {
                        color: $main-text-color;
                        font-weight: 600; } }
                .actions {
                    padding-top: 30px;
                    @include phone {
                        padding-top: 15px; }
                    a {
                        font-family: $font-text;
                        font-weight: 600;
                        color: $main-color;
                        padding: 18px 15px;
                        text-decoration: none;
                        @include phone {
                            display: block;
                            text-align: center;
                            padding: 10px 6px; } }
                    a.btn-condoleances {
                        cursor: pointer;
                        float: left;
                        background-color: transparent;
                        transition: background-color .8s ease-in-out, color .8s ease-in-out;
                        &:hover {
                            color: white;
                            background-color: $main-color; }
                        @include phone {
                            float: none; } }
                    a.btn-faire-part {
                        float: right;
                        border: 2px solid transparent;
                        transition: border-color .8s ease-in-out, color .8s ease-in-out;
                        &:hover {
                            color: #605B57;
                            border: 2px solid #605B57; }
                        @include phone {
                            float: none; } } } } } }
    &.necro-cards {
        .content {
            @include phone {
                padding-top: 0; }
            .necrologies {
                .necrologie {
                    margin: 80px 0;
                    width: 860px;
                    max-width: 80%;
                    height: 400px;
                    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
                    border: none;
                    padding: 40px 20px;
                    position: relative;
                    @include phone {
                        margin: 20px 0;
                        height: auto;
                        width: 100%;
                        max-width: 100%;
                        padding: 20px; }
                    img.avatar {
                        border-radius: 0;
                        height: 410px;
                        width: 380px;
                        position: absolute;
                        left: 40px;
                        bottom: 60px;
                        @include phone {
                            position: static;
                            width: 100%;
                            height: 200px; } }
                    h4.name, .dates, .funerailles, p.gender {
                        padding-left: 440px;
                        text-align: left;
                        @include phone {
                            padding-left: 0; } }
                    .dates, .funerailles {
                        padding: 12px;
                        padding-left: 440px;
                        @include phone {
                            padding-left: 0; }
                        p {
                            font-size: 18px;
                            font-family: $font-text;
                            @include phone {
                                font-size: 16px; } } }
                    h4.name {
                        position: relative;
                        padding-top: 8px;
                        padding-bottom: 20px;
                        font-size: 24px;
                        &:after {
                            position: absolute;
                            left: 440px;
                            content: ' ';
                            height: 3px;
                            border-radius: 1.5px;
                            background-color: $main-color;
                            width: 64px;
                            bottom: 0; }
                        @include phone {
                            padding-top: 0;
                            &:after {
                                left: 0; } } }
                    p {}


                    .dates {
                        padding-top: 20px; }
                    .actions {
                        text-align: left;
                        padding-left: 440px;
                        padding-top: 0;
                        @include phone {
                            padding-left: 0; }
                        a {

                            display: inline-block; }
                        a.btn-condoleances {
                            float: none;
                            background-color: $main-color;
                            color: white;
                            border: 2px solid $main-color; }
                        a.btn-faire-part {
                            float: none;
                            color: #605B57;
                            border: 2px solid #605B57;
                            margin-left: 20px; } }
                    &:nth-child(even) {
                        padding-left: 40px;
                        @include phone {
                            padding-left: 20px; }
                        img.avatar {
                            left: auto;
                            right: 40px; }
                        h4.name, .dates, .funerailles, p.gender {
                            padding-left: 0;
                            padding-right: 440px;
                            text-align: left;
                            @include phone {
                                padding-right: 0; }
                            &::after {
                                left: 0px; } }
                        .actions {
                            padding-left: 0; } } } } } } }
