section.widget.three-columns {
    .content {
        @include phone {
            padding: 10% 5%; }
        h2 {
            margin-bottom: 60px; }
        .column {
            width: 33.33%;
            display: inline-block;
            vertical-align: top;
            padding: 2%;
            a {
                color: $main-color;
                text-decoration: none; }
            @include phone {
                width: 100%;
                padding: 0;
                padding-bottom: 40px; }

            &:nth-child(2) {
                padding-left: 0;
                padding-right: 4%;
                @include phone {
                    padding-right: 0; } }
            &:last-child {
                padding-right: 0;
                padding-left: 4%;
                @include phone {
                    padding-left: 0;
                    padding-bottom: 0; } }
            h4 {
                margin-bottom: 30px; } } } }
