//widget path '../widgets/'
@import '../widgets/header/header';
@import '../widgets/website/footer';
@import '../widgets/website/menu';
@import '../widgets/banner_text/banner_text';
@import '../widgets/intro/intro';
@import '../widgets/three_columns/three_columns';
@import '../widgets/showcase/showcase';
@import '../widgets/prevoyance/prevoyance';
@import '../widgets/necro/necro';
@import '../widgets/testimonials/testimonials';
@import '../widgets/condoleances/condoleances';
@import '../widgets/text_blocks/text_blocks';
@import '../widgets/contact/contact';
@import '../widgets/map/map';
@import '../widgets/form/form';
@import '../widgets/gallery/gallery';
@import '../widgets/link_list/link_list';
@import '../widgets/snackbar/snackbar';
