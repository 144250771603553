section.gallery {
    width: 70%;
    margin: auto;
    padding-top: 70px;
    @include tablet {
        width: 80%; }
    @include phone {
        width: 90%; }
    p.intro-line {
        padding: 20px 0;
        font-size: 18px; }
    a {
        color: $main-color; }
    .img-box {
        padding-bottom: 40px;
        width: 100%;
        line-height: 0;
        -webkit-column-count: 3;
        -webkit-column-gap: 0px;
        -moz-column-count: 3;
        -moz-column-gap: 0px;
        column-count: 3;
        column-gap: 0px;
        @include tablet {
            -webkit-column-count: 2;
            -moz-column-count: 2;
            column-count: 2; }
        @include phone {
            -webkit-column-count: 1;
            -moz-column-count: 1;
            column-count: 1; } }
    img {
        margin: 2%;
        width: 96%;
        height: auto !important; }

    .call-to-action {
        text-align: center;
        padding-bottom: 100px;
        a.cta {
            display: inline-block;
            cursor: pointer;
            font-family: $font-text;
            margin-top: 16px;
            border: none;
            background-color: $main-color;
            color: white;
            padding: 10px 30px;
            font-size: 18px;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            text-decoration: none;
            margin-top: 40px; } } }
