main header.header {
    min-height: 680px;
    .wrap {
        .image-back {
            .input-file-wrap {
                label {
                    i {
                        left: 75% !important; } } } } }
    &.small-header {
        height: 45vh;
        min-height: auto; }
    label {
        display: block;
        text-align: center;
        color: white;
        padding-bottom: 40px; }
    img.funerailles-logo {
        width: 500px;
        margin: auto;
        @include tablet {
            width: 350px; }
        @include phone {
            width: 280px; } }
    img.laurier {
        margin-top: 40px;
        height: 80px; }
    img.background {
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        filter: brightness(70%);
        z-index: -1;
        &.noindex {
            z-index: 0; } }
    img.funebra {
        position: absolute;
        right: 0;
        bottom: 0;
        width: auto;
        height: 72px;
        @include phone {
            right: auto;
            left: 50%;
            transform: translateX(-50%);
            height: 48px;
            bottom: 10px; } }
    blockquote {
        width: 70%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        @include tablet {
            width: 90%; }

        .q-icon {
            position: absolute;
            i {
                color: white;
                font-size: 48px;
                @include tablet {
                    font-size: 24px; } } }
        .left-q {
            left: 0; }
        .right-q {
            right: 0; }
        q {
            font-style: italic;
            display: inline-block;
            padding: 0 60px;
            font-size: 24px;
            line-height: 36px;
            color: white;
            quotes: none;
            @include phone {
                font-size: 18px;
                line-height: 28px; } } }
    @include tablet {
        padding: 0 5%; }
    &.short {
        height: 50vh; }
    hgroup {
        text-align: center;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        h1 {
            line-height: normal;
            text-align: center;
            color: #FFFFFF;
            font-size: 48px;
            @include tablet {
                font-size: 48px;
                line-height: normal; }
            @include phone {
                font-size: 28px;
                line-height: normal; } }
        @include tablet {
 }            //left: 5%
        @include phone {
            transform: translateX(-50%);
            width: 90%;
            top: 20%;
            left: 50%; } } }

main header.header, header.short-header {
    a.call-to-action {
        text-align: center;
        position: absolute;
        left: 50%;
        bottom: 40px;
        transform: translateX(-50%);
        line-height: 1.5em;
        span.number {
            font-weight: 600; }
        @include phone {
            bottom: 80px; } } }
