section.testimonials {
    position: relative;
    img.background {
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0; }
    .content {
        padding: 50px 10%;
        text-align: center;
        @include tablet {
            width: 90%;
            margin: auto;
            padding: 40px 0; }
        h2 {
            padding: 60px 0; }
        .content-block {
            background-color: rgba( 255, 255, 255, 0.7);
            position: relative;
            &::before {
                content: ' ';
                position: absolute;
                background-image: url('../images/q-left.png');
                background-repeat: no-repeat;
                width: 70px;
                height: 70px;
                background-size: contain;
                left: 10%;
                top: 100px;
                @include tablet {
                    display: none; } }
            &::after {
                content: ' ';
                position: absolute;
                background-image: url('../images/q-right.png');
                background-repeat: no-repeat;
                width: 70px;
                height: 70px;
                background-size: contain;
                right: 10%;
                bottom: 100px;
                @include tablet {
                    display: none; } } }
        .slider {
            vertical-align: top;
            display: inline-block;
            width: 70%;
            height: 520px;
            position: relative;
            overflow: hidden;
            @include tablet {
                width: 100%; }
            @include phone {
                height: 600px; }
            .container {
                height: 100%;
                width: 100%;
                position: absolute;
                overflow: hidden;
                white-space: nowrap;
                .slider-content {
                    transition: transform 1s linear;
                    transform: translate(0px);
                    position: relative; }
                .testi {
                    vertical-align: top;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    display: inline-block;
                    position: relative;
                    .testi-content {
                        left: 0%;
                        right: 0%;
                        height: auto;
                        position: absolute;
                        p {
                            line-height: 36px;
                            white-space: normal;
                            color: $main-text-color;
                            @include phone {
                                line-height: 28px;
                                padding: 0 10px; } } }
                    p.sig {
                        color: $main-text-color;
                        position: absolute;
                        bottom: 50px;
                        left: 50%;
                        font-family: $font-headers;
                        transform: translateX(-50%); } } }
            .selector {
                position: absolute;
                left: 50%;
                bottom: 12px;
                transform: translateX(-50%);
                width: 80%;
                @include phone {
                    width: 90%; }
                .select {
                    cursor: pointer;
                    display: inline-block;
                    margin: 6px;
                    background-color: white;
                    width: 20px;
                    height: 20px;
                    box-shadow: 0 3px 6px black;
                    &.selected {
                        background-color: $main-color; } } } } } }
