section.banner-text {
    width: 100%;
    height: 360px;
    background-color: $main-color;
    position: relative;
    overflow: hidden;
    @include phone {
        height: 300px;
        margin-bottom: 0; }
    .content {
        @include phone {
            padding: 10% 5%; } }
    .full-banner {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
        filter: brightness(50%);
        @include tablet {
            width: 100%;
            left: 0%; }
        @include phone {
            border-radius: 0;
            box-shadow: none;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0; } }
    .content {
        height: 100%;
        text-align: center;
        h3 {
            color: white;
            margin-bottom: 30px;
            @include tablet {
                font-size: 24px; } }
        p {
            color: white;
            margin-bottom: 40px;
            @include tablet {
                font-size: 16px; } } } }
