section.widget#link-list {
    .link {
        display: inline-block;
        vertical-align: top;
        width: 50%;
        padding: 20px;
        @include tablet {
            width: 100%;
            padding: 10px 0; }
        @include phone {
            width: 100%;
            padding: 10px 0; }
        h4 {
            font-size: 24px;
            @include phone {
                font-size: 18px; } }
        a {
            display: block;
            color: $main-color;
            font-size: 20px;
            padding: 6px 0; }

        label {
            font-family: $font-text;
            color: $sec-text-color;
            font-size: 16px;
            @include phone {
                font-size: 14px; } } } }
