section.text-blocks {
    .content {
        @include phone {
            padding: 30px 5%; }
        a.assurance {
            color: $main-color;
            font-family: $font-text;
            display: block;
            padding: 4px 0; }
        .call-to-action {
            text-align: center;
            a.cta {
                display: inline-block;
                cursor: pointer;
                font-family: $font-text;
                margin-top: 16px;
                border: none;
                background-color: $main-color;
                color: white;
                padding: 10px 30px;
                font-size: 18px;
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                text-decoration: none;
                margin-top: 40px; } }

        .text-block {
            padding: 50px 0;
            .text, .image {
                display: inline-block;
                vertical-align: middle;
                width: 50%;
                @include tablet {
                    width: 100%;
                    display: block;
                    padding: 20px 0; }
                p {
                    line-height: 28px;
                    padding-top: 30px;
                    &.quote {
                        font-style: italic; } }
                ul {
                    padding-top: 12px;
                    font-family: $font-text;
                    li {
                        padding: 4px 0;
                        margin-left: 20px;
                        color: $sec-text-color; } }
                .contact-list {
                    .label {
                        padding-top: 8px;
                        color: $main-text-color;
                        font-family: $font-text; }
                    a {
                        color: $main-color;
                        font-family: $font-text;
                        display: block;
                        padding: 4px 0; } } }
            .image {
                img {
                    width: 100%;
                    object-fit: cover; } }
            &:not(:first-child) {
                @include tablet {
                    border-top: 1px solid lightgray; } }
            &:nth-child(odd) {
                .text {
                    padding-right: 30px;
                    @include tablet {
                        padding-right: 0; } }
                .image {
                    padding-left: 30px;
                    @include tablet {
                        padding-left: 0; } } }
            &:nth-child(even) {
                .text {
                    padding-left: 30px;
                    @include tablet {
                        padding-left: 0; } }
                .image {
                    padding-right: 30px;
                    @include tablet {
                        padding-right: 0; } } } } } }
